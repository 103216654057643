/* exported Terra.activate Terra.ariaSelected */

(function ($, Terra) {
  // Set the target to active and remove active from all siblings
  Terra.activate = function(target, customActiveClass) {
    var $target = $(target),
    activeClass = customActiveClass || 'active';

    $target.siblings().removeClass(activeClass);
    $target.addClass(activeClass);
  };

  /*
   * Updated version of #activate above.
   * Used for modern aria-selected with additional radio vs. checkbox functionality built in.
   *
   * The first argument can be a string or an object. It is the target.
   * The second argument is an optional options object with the following members:
   *   selectedStyle {String} - if set to 'multiple', sibling elements will not be toggled
   *     providing multiple-item rather than single-item selection.
   *   customEvent {String} - if specified, the custom event will be triggered once an item is selected.
   */
  Terra.ariaSelected = function(target, options) {
    var $target = $(target);

    options = options || {}; // Ensure options is truly optional

    // If the current selection should not be modified, do nothing.
    if ($target.hasClass('selection-static')) {
      return;
    }

    // If current selection is already selected,
    // Unselect if in checkbox mode then get out early.
    if ($target.is('[aria-selected]')) {
      if (options.selectStyle === 'multiple') {
        $target.removeAttr('aria-selected');
      }

      return false;
    }

    if (options.selectStyle !== 'multiple') {
      $target.siblings().removeAttr('aria-selected');
    }

    $target.attr('aria-selected', 'true');

    if (options.customEvent) {
      $target.trigger(options.customEvent);
    }
  };
}(jQuery, Terra));
