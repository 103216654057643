(function ($, Terra, window, undefined) {
  /**
   * TOGGLER PUBLIC CLASS DEFINITION
   *
   * @class Toggler
   * @constructor
   * @param {String|Object} element - The selected DOM node.
   * @param {Object} options - Optional object of options used to override the defaults.
   */
  var Toggler = function (element, options) {
    this.options  = $.extend({}, Toggler.DEFAULTS, options);

    this.$element = $(element);
    this.target  = this.options.target || element.hash;

    if (this.options.toggle) {
      this.toggle();
    }
  };

  Toggler.DEFAULTS = {
    slide  : false,
    toggle : true
  };

  Toggler.prototype.constructor = Toggler;

  /**
   * Toggler.hide
   * It hides the target element, triggers the Terra.toggler.hidden event,
   *   removes the toggler-shown class from the trigger element,
   *   and adds the hide class to the target element.
   *
   * @method hide
   */
  Toggler.prototype.hide = function () {
    var $target  = $(this.target);

    if (this.options.slide === true) {
      $target.slideUp(Terra.transitionSpeed, _hidden);
    } else {
      $target.fadeOut(Terra.transitionSpeed, _hidden);
    }

    // Update class hooks for the consumers to use
    this.$element.removeClass('toggler-shown');
    $target.addClass('hide');
  };

  /**
   * Toggler.show
   * It shows the target element, triggers the Terra.toggler.shown event,
   *   adds the toggler-shown class to the trigger element,
   *   and removes the hide class from the target element.
   *
   * @method show
   */
  Toggler.prototype.show = function () {
    var $target  = $(this.target);

    if (this.options.fitToViewport === true) {
      $target.css('max-height', _adjustedMaxHeight($target) + 'px');
    }

    if (this.options.slide === true) {
      $target.slideDown(Terra.transitionSpeed, _shown);
    } else {
      $target.fadeIn(Terra.transitionSpeed, _shown);
    }

    // Update class hooks for the consumers to use
    this.$element.addClass('toggler-shown');
    $target.removeClass('hide');
  };

  /**
   * Toggler.toggle
   * It determines whether to show or hide the target element based on the presence of
   *   the hide class on the target element.
   *
   * @method toggle
   */
  Toggler.prototype.toggle = function () {
    this[$(this.target).hasClass('hide') ? 'show' : 'hide']();
  };

  var old = $.fn.toggler;

  /**
   * TOGGLER PLUGIN DEFINITION
   * It sets up a toggler(s) for the provided trigger(s) and target(s).
   *
   * The opts variable may alternatively be a string of 'show' or 'hide' to directly invoke those internal methods.
   *
   * @method toggler
   * @chainable
   * @param {String|Object} opts - Name of function to call, or object of options.
   * @param {Boolean} [opts.slide]   - Whether to slide the target in/out of view or not.
   * @param {Boolean} [opts.toggle]  - Whether to attempt to set a toggler or not.
   * @param {Boolean} [opts.visible] - Whether to display the target by default or not.
   * @return {Object} - The selected element(s).
   */
  $.fn.toggler = function (opts) {
    return this.each(function () {
      var $this   = $(this),
          data    = $this.data('toggler'),
          options = $.extend({}, $this.data(), typeof opts === 'object' && opts);

      if (data === undefined) {
        $this.data('toggler', (data = new Toggler(this, options)));
      }

      // Call internal function if the consumer passes it.
      if (typeof opts === 'string') {
        data[opts]();
      }
    });
  };

  $.fn.toggler.Constructor = Toggler;

  // TOGGLER NO CONFLICT
  // ===================

  $.fn.toggler.noConflict = function () {
    $.fn.toggler = old;

    return this;
  };

  // TOGGLER API
  // ===========

  $(document).on('click', '.toggler', function (e) {
    var $this = $(this);

    $this.toggler($this.data('toggler') ? 'toggle' : null);

    e.preventDefault(); // Don't let buttons or anchor elements do their normal behavior.
  });

  // HELPERS
  // =======

  // Calculates the available height between the given target and the bottom of the window.
  function _adjustedMaxHeight($target) {
    var maxHeight,
        $parent      = $target.parent(),
        $window      = $(window),
        minHeight    = parseInt($target.css('min-height'), 10),
        parentBottom = $parent.offset().top - $window.scrollTop() + $parent.height();

    // Subtract 10px so that toggled content isn't flush against the bottom of the screen.
    maxHeight = $window.height() - parentBottom - 10;

    if (maxHeight <= 0) {
      maxHeight = null;
    }

    if (maxHeight !== null && maxHeight < minHeight) {
      maxHeight = minHeight;
    }

    return maxHeight;
  }

  function _shown() {
    $(this).trigger('Terra.toggler.shown');
  }

  function _hidden() {
    $(this).trigger('Terra.toggler.hidden');
  }
}(jQuery, Terra, this));
