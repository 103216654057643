/* exported Terra.Overlay */

// Overlay API
// --------------------------------------------------

(function ($, Terra, undefined) {
  var lastFocusedElement = null,
      $overlay = $('<div class="overlay" tabindex="-1"></div>');

  // Public API
  // --------------------------------------------------
  Terra.Overlay = {};

  /**
   * Determines if the overlay is visible or hidden.
   * @return {Boolean} True if the overlay is visible, false if its hidden.
   */
  Terra.Overlay.isVisible = function() {
    return $('.overlay:visible').length > 0;
  };

  /**
   * Shows the overlay.
   * @chainable
   */
  Terra.Overlay.show = function() {
    if (this.isVisible()) {
      // Do _not_ add the overlay into the DOM more than once.
      return this;
    }

    // Remember the element that had focus prior to the overlay being present.
    lastFocusedElement = document.activeElement;

    // The overlay must have a tabindex in order for it to be focusable.
    $('body').prepend($overlay);

    // Focus on the overlay.
    $overlay.focus();

    // Prevent focus from leaving the overlay.
    $(document).on('focusin.terra.overlay', function(e) {
      // If focus is changed to another element, then focus must be set back to the overlay.
      // However, changing focus back to the overlay will cause the 'focusin' event to immediately refire.
      // In this case, focus should not be reapplied because otherwise the event will continue to refire
      // ad infinitum - thus causing a stackflow.
      //
      // The solution is to check if the element getting focus is the overlay and if it is, then don't set
      // focus to it again.
      if ($overlay[0] === e.target) {
        return;
      }
      $overlay.focus();
    });

    // Fire trigger.
    $(document).trigger('Terra.overlayOpened');

    return this;
  };

  /**
   * Presents the overlay with content.
   * @param selector A selector to an element to be presented in the center of the overlay.
   * @chainable
   */
  Terra.Overlay.showWithContent = function(selector) {
    if (this.isVisible()) {
      // Do _not_ add the overlay into the DOM more than once.
      return this;
    }

    // Show the desired content in the overlay.
    $(selector).show();

    return this.show();
  };

  /**
   * Hides the overlay.
   * @chainable
   */
  Terra.Overlay.hide = function() {
    // Remove the overlay.
    $overlay.remove();

    // Hide all content that could've been presented with the overlay.
    $('.overlay-content').hide();

    // Remove the focus event.  This needs to be done prior to restoring focus to the last focused element.
    $(document).off('focusin.terra.overlay');

    // Bring focus to the element selected prior to the overlay being shown.
    if (lastFocusedElement) {
      $(lastFocusedElement).focus();
      lastFocusedElement = null;
    }

    // Fire trigger.
    $(document).trigger('Terra.overlayClosed');

    return this;
  };
}(jQuery, Terra));

