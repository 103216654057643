(function ($) {
  /*
   * Add Select All checkbox for table forms
   *
   * Add the .select-all class to a table within a form,
   *   and add checkboxes to the table rows, including <thead>.
   * Any checkbox located in <thead> will act as a
   *   'Select All' toggle for all checkboxes in <tbody>.
   * All checkboxes in the table will be affected:
   *   having separate, unattached checkboxes in the table
   *   is not possible with this table class.
   * When Select All is active, unchecking any checkbox
   *   in the <tbody> will also uncheck Select All.
   */

  /*
   * When a 'Select All' checkbox in the header is clicked, get all the child
   *   checkboxes in the body and set them to match the parent checkbox.
   */
  function checkboxTableSelectAll() {
    var $childCheckBoxes = $(this).closest('table').find('tbody input:checkbox');

    $childCheckBoxes.prop('checked', $(this).prop('checked'));
  }

  /*
   * When a child checkbox in the body of a table.select-all is clicked,
   *   check if it's being unchecked, and if it is, get the parent checkbox
   *   in the header and uncheck it, because at least one child is not selected.
   * The opposite functionality is intentionally excluded: if the box is being
   *   checked, nothing happens.  The user must explicitly turn on select all.
   */
  function checkboxTableSelectOne() {
    if ($(this).prop('checked') === false) {
      $(this).closest('table').find('thead input:checkbox').prop('checked', false);
    }
  }

  $(document)
    .on('click', 'form table.table-select-all thead input:checkbox', checkboxTableSelectAll)
    .on('click', 'form table.table-select-all tbody input:checkbox', checkboxTableSelectOne);
}(jQuery));
