(function ($) {
  // Setup the are you sure plugin on forms that call for it.
  $(document).on('focus.terra.areyousure', 'form.form-checked', function () {
    var $this = $(this);

    // Check to see if this form has already been initialized with areYouSure.
    if ($this.data('terra.areyousure')) {
      return;
    }

    $this
      .areYouSure({message: Terra._i18n('Terra/areYouSure/unsaved', 'You have unsaved changes.')})
      .data('terra.areyousure', true); // Add the hook to test against next time this code is run.
  });
}(jQuery));
