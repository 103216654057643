/* exported Terra.selectTableRow */

(function ($, Terra) {
  // Select Table Row
  // --------------------------------------------------
  // Allows for tr elements within a table's tbody element to be highlighted,
  // or selected, upon a click event. This is restricted to tbody so
  // that tr's within a thead or tfoot are not selectable.
  Terra.selectTableRow = function () {
    var $this        = $(this),
        $parentTable = $this.closest('table'),
        $parentTbody = $this.parent('tbody');

    if ($parentTable.hasClass('table-grouped-rows')) {
      $parentTbody.toggleClass('selected');

      // Deselect siblings if not multi-selectable
      if (!$parentTable.hasClass('table-multi-selectable')) {
        $parentTbody.siblings().removeClass('selected');
      }
    } else {
      $this.toggleClass('selected');

      // Deselect siblings if not multi-selectable
      if (!$parentTable.hasClass('table-multi-selectable')) {
        $this.siblings().removeClass('selected');
      }
    }

    $this.trigger('Terra.table.rowSelected');
  };

  // Event handlers
  $(document).on('click', '.table-selectable tbody tr, .table-multi-selectable tbody tr', Terra.selectTableRow);
}(jQuery, Terra));
