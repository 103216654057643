(function ($) {
  function _listSelectable() {
    Terra.ariaSelected(this, { customEvent: 'terra.list.itemSelected' });
  }

  function _listMultiSelectable() {
    Terra.ariaSelected(this, {
      customEvent : 'terra.list.itemSelected',
      selectStyle : 'multiple'
    });
  }

  $(document)
    .on('click', '.list-selectable li', _listSelectable)
    .on('click', '.list-multi-selectable li', _listMultiSelectable);
}(jQuery));
