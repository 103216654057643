/* exported Terra.toggleContentPanel */

(function ($, Terra) {
  /****************** Content Panels ********************/

  // Show/hide collapsible content panel when clicked
  // when the content panel is closed and toggling to open the expectation is that it will not have
  // the collapsed class so ajax-disabled will be true allowing to load via ajax
  // when the content panel is opened, and toggling to closed, ajax will be disabled since new data doesn't need to load
  Terra.toggleContentPanel = function (e) {
    var $this         = $(e.target),
        $contentPanel = $this.closest('.content-panel'),
        $collapseArea = $contentPanel.children('.content-panel-inner');

    $collapseArea
      .stop(true, true)
      .slideToggle('fast', function () {
        // Fire an event that consuming applications can listen to.
        $contentPanel.trigger('Terra.contentPanelToggled');
      });

    // toggleClass returns a promise object that enables me to see when the action is done.
    // https://api.jquery.com/promise/
    $contentPanel.toggleClass('collapsed').promise().done(function ($obj) {
      var $ajaxHeader = $obj.children('[data-ajax-url]');

      if (!$obj.hasClass('collapsed')) {
        Terra.justifyHeight($obj);
      }

      // Don't reload the content when the content panel is open and being closed
      if ($ajaxHeader.length > 0 && !$obj.hasClass('collapsed')) {
        Terra.ajaxRequestHandler(e, $ajaxHeader, $obj.find('.content-panel-body'));
      }
    });
  };

  // Event handlers
  $(document)
    .on('click', '.content-panel.collapsible > header', Terra.toggleContentPanel)
    .on(
      'click',
      '.content-panel .actions .btn-group, ' +
      '.content-panel .actions .btn, ' +
      '.content-panel .actions a, ' +
      '.content-panel .actions button',
      function (e) {
        // This prevents click events on buttons nested inside content panels from propagating up
        // to the content panels toggle event.
        if ($(this).parent('.btn-group').length === 0) {
          e.stopPropagation();
        }
      }
    );
}(jQuery, Terra));
