(function (undefined) {
  'use strict';

  // Make the ajax call and either load the content on success or issue an error message otherwise.
  // This will place content in the targetSelector.
  function fireAjaxRequest(options, $ajaxTrigger){
    $.ajax({
      url  : options.url,
      type : options.method,
      data : options.data,
      error: function () {
        var i18n_error = Terra._i18n('Terra/ajax/error', 'This content failed to load.'),
            msg        = $ajaxTrigger.data('error-msg');

        // Consumers can pass a custom error-message via the data-error-msg attribute on the trigger.
        $(options.targetSelector).html(
          '<p class="text-error">' +
          (msg === undefined ? i18n_error : msg) +
          '</p>'
        );

        ajaxEvent($ajaxTrigger, 'ajaxFailed', options);
      },
      success: function (data) {
        $(options.targetSelector).html(data);

        ajaxEvent($ajaxTrigger, 'ajaxLoaded', options);

        if ($ajaxTrigger.data('allow-reload') === false) {
          $ajaxTrigger.data('ajax-disabled', true);
        }
      }
    });
  }

  function ajaxEvent($ajaxTrigger, type, options) {
    var ajaxLoadedEvent = $.Event(type, {
      url            : options.url,
      targetSelector : options.targetSelector
    });

    $ajaxTrigger.trigger(ajaxLoadedEvent);
  }

  /*
   * Set up the request options and fire the AJAX request.
   *
   * @param e {Object} The AJAX init event (probably click)
   * @param $ajaxTrigger {Object} The element that triggered the AJAX init event
   * @param targetSelector {String|Object} Fallback selector or element to put the AJAX results in
   */
  function ajaxRequestHandler(e, $ajaxTrigger, targetSelector) {
    if ($ajaxTrigger.data('ajax-disabled') !== true) {

      var options = {
        // check for url from link first, then form, then data attribute
        url            : $ajaxTrigger.attr('href') || $ajaxTrigger.attr('action') || $ajaxTrigger.data('ajax-url'),
        method         : $ajaxTrigger.data('method') || $ajaxTrigger.attr('method') || 'get',
        data           : $ajaxTrigger.serializeArray(),
        targetSelector : $ajaxTrigger.data('ajax-target') || targetSelector
      };

      fireAjaxRequest(options, $ajaxTrigger);
    }

    e.preventDefault();
  }

  // Add public API
  Terra.ajaxRequestHandler = ajaxRequestHandler;

  // Set up DOM events
  $(document)
    .on('click', ':not(form)[data-ajax-target]', function (e) {
      ajaxRequestHandler(e, $(this));
    })
    .on('submit', 'form[data-ajax-target]', function (e) {
      ajaxRequestHandler(e, $(this));
    });
}());
