(function ($, Terra) {
  // Truncates the long text with a show more link if it exceeds the given truncate limit
  function truncateLongText(e) {
    $(e.target).find('[data-truncate-limit]:not([aria-live])').each(function() {
      var $this     = $(this),
          truncateLimit = $this.data('truncate-limit'),
          content   = $this.text().trim(),
          $replacableElement = $('<div></div>');

      if(content.length > truncateLimit) {
        // Bind a copy of the original markup to the element,
        // so it can be used once the truncated text is expanded.
        // This allows each element to uniquely have its own markup bound to it, keeping things simple.
        $this.data('originalHTML', $this.html());

        var shortText,
            moreLabel = $this.data('more-label') || Terra._i18n('Terra/truncateText/showMore', 'Show more'),
            button    = '<button class="btn btn-link truncate-trigger" aria-expanded="false">' +
                        moreLabel +
                        '</button>';

        shortText = content.substr(0, Math.min(truncateLimit,
          content.substr(0, truncateLimit).lastIndexOf(' '))) + '...';

        $replacableElement.html(shortText);
        $this.html($replacableElement);
        $this.append(button);
        $this.data('elementToChange', $replacableElement);
        $this.data('truncatedText', shortText);
        $this.attr('aria-live', 'polite'); // Added for accessibility when the truncation gets triggered.
      }
    });
  }

  // Toggles to display/hide text and more/less link
  function toggleTruncation() {
    var $this           = $(this),
        $truncatableDiv = $this.closest('[data-truncate-limit]');

    if($this.attr('aria-expanded') === 'true') {
      $this.attr('aria-expanded', 'false');
      $truncatableDiv.data('elementToChange').html($truncatableDiv.data('truncatedText'));
      $this.html($truncatableDiv.data('more-label') || Terra._i18n('Terra/truncateText/showMore', 'Show more'));
    } else {
      $this.attr('aria-expanded', 'true');
      $truncatableDiv.data('elementToChange').html($truncatableDiv.data('originalHTML'));
      $this.html($truncatableDiv.data('less-label') || Terra._i18n('Terra/truncateText/showLess', 'Show less'));
    }

    return false;
  }

  $(document)
    .on('Terra.init', truncateLongText)
    .on('click', '[data-truncate-limit] .truncate-trigger', toggleTruncation);
}(jQuery, Terra));
