/* exported Terra.SlidePanel */

// Slide Panel API
// --------------------------------------------------

(function ($, Terra, undefined) {
  /**
   * Checks if the current slider behavior is set to "split".
   * @return {Boolean} True if the slider is using split behavior.
   */
  function isSplitBehavior() {
    return this.$container.data('slide-panel-behavior') === 'split';
  }

  /**
   * Check if the screen is large enough to show both the master and detail.
   * @return {Boolean} True if the screen is large enough.
   */
  function isLargeScreen() {
    var mediaQueryName = Terra.getMediaQueryName();
    if (mediaQueryName === undefined) {
      return false;
    }
    // 'xxs' and 'xs' are considered as small screen.
    // This is used to determine the behavior of the split slider.
    return (['xxs', 'xs'].indexOf(mediaQueryName) === -1);
  }

  /**
   * Helper to toggle aria state for the detail container.
   * @return {Boolean} True if the state was changed or untouched, false if the state cannot be changed.
   */
  function toggleDetailsAccessibility(hidden) {
    if (isLargeScreen.call(this)) {
      if (isSplitBehavior.call(this)) {
        // The state of the aria attribute should not be changed for split behavior on large screens.
        return false;
      } else {
        // The state of the aria attribute for the detail container should not be changed for non-split behavior
        // on large screens but return true so that the aria attribute for the master container can be updated.
        return true;
      }
    } else {
      // On small screens details visibility needs to be toggled for screen readers.
      this.$detail.attr('aria-hidden', hidden.toString());
    }
    return true;
  }

  /**
   * Updates the aria labels of the split panel based on its
   * current state.
   */
  function updateAriaAttributes() {
    if (isLargeScreen.call(this)) {
      if (isSplitBehavior.call(this)) {
        // If the screen is large for the split behavior, then the master and detail are forced to be visible.
        // It doesn't matter if the master is flagged as open or closed.
        this.$master.attr('aria-hidden', false);
      } else {
        if (this.isOpen()) {
          this.$master.attr('aria-hidden', false);
        } else {
          this.$master.attr('aria-hidden', true);
        }
      }
      this.$detail.attr('aria-hidden', false);
    } else {
      if (this.isOpen()) {
        this.$master.attr('aria-hidden', false);
        this.$detail.attr('aria-hidden', true);
      } else {
        this.$master.attr('aria-hidden', true);
        this.$detail.attr('aria-hidden', false);
      }
    }
  }

  /**
   * Initializes a SlidePanelAPI.
   * This should be called with "new". (e.g. var myPanel = new SlidePanelAPI)
   * @chainable
   */
  var SlidePanelAPI = function($slidePanelContainer) {
    this.$container = $slidePanelContainer;
    this.$master = this.$container.children('.slide-panel-master');
    this.$detail = this.$container.children('.slide-panel-detail');
    return this;
  };

  /**
   * Determines if the slide panel is open or closed.
   * @return {Boolean} True if the panel is open, false if its closed.
   */
  SlidePanelAPI.prototype.isOpen = function() {
    return this.$container.hasClass('slide-panel-open');
  };

  /**
   * Shows the slide panel.
   * @chainable
   */
  SlidePanelAPI.prototype.open = function() {
    if (!toggleDetailsAccessibility.call(this, true)) {
      // Opening the panel cannot be done when using split behavior on large screens.
      return this;
    }
    this.$master.attr('aria-hidden', 'false');
    this.$container
      .addClass('slide-panel-open')
      .trigger('Terra.slidePanelOpened');
    return this;
  };

  /**
   * Hides the slide panel.
   * @chainable
   */
  SlidePanelAPI.prototype.close = function() {
    if (!toggleDetailsAccessibility.call(this, false)) {
      // Closing the panel cannot be done when using split behavior on large screens.
      return this;
    }
    this.$master.attr('aria-hidden', 'true');
    this.$container
      .removeClass('slide-panel-open')
      .trigger('Terra.slidePanelClosed');
    return this;
  };

  /**
   * Opens the panel if it was closed.
   * Closes the panel if it was open.
   * @chainable
   */
  SlidePanelAPI.prototype.toggle = function() {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
    return this;
  };

  // Public API
  // --------------------------------------------------

  /**
   * Creates and returns a new SlidePanelAPI.
   * @param {String} A selector refering to the slide panel container.
   */
  Terra.SlidePanel = function (selector) {
    return new SlidePanelAPI($(selector));
  };

  // Slide Panel Initialization
  // --------------------------------------------------

  function slidePanelToggle() {
    Terra.SlidePanel($(this).data('slide-panel-target')).toggle();
  }

  /**
   * Changes the accessibility attributes of all master/details.
   * On larger screen sizes, the aria attributes must be set so screen readers can read both
   * master and detail since they are both visible.
   * On smaller screens, only one of them (the master or detail) is shown, so the aria attributes
   * must be updated accordingly.
   */
  var splitBehaviorEnabled    = false,
      pushBehaviorEnabled     = false,
      overlayBehaviorEnabled  = false,
      squishBehaviorEnabled   = false,
      splitBehaviorSelector   = buildBehaviorSelector('split'),
      pushBehaviorSelector    = buildBehaviorSelector('push'),
      overlayBehaviorSelector = buildBehaviorSelector('overlay'),
      squishBehaviorSelector  = buildBehaviorSelector('squish');

  function buildBehaviorSelector(behavior) {
    return 'body[data-slide-panel-behavior="' + behavior + '"], ' +
           'div[data-slide-panel-behavior="' + behavior + '"], ' +
           'main[data-slide-panel-behavior="' + behavior + '"]';
  }

  function updateSliderAccessibility() {
    updateAriaAttributesForBehavior(splitBehaviorEnabled, splitBehaviorSelector);
    updateAriaAttributesForBehavior(pushBehaviorEnabled, pushBehaviorSelector);
    updateAriaAttributesForBehavior(overlayBehaviorEnabled, overlayBehaviorSelector);
    updateAriaAttributesForBehavior(squishBehaviorEnabled, squishBehaviorSelector);
  }

  function updateAriaAttributesForBehavior(behaviorEnabled, behaviorSelector) {
    if (behaviorEnabled) {
      $(behaviorSelector).each(function() {
        updateAriaAttributes.call(new SlidePanelAPI($(this)));
      });
    }
  }

  function initSliderAccessibility() {
    splitBehaviorEnabled   = $(splitBehaviorSelector).length > 0;
    pushBehaviorEnabled    = $(pushBehaviorSelector).length > 0;
    overlayBehaviorEnabled = $(overlayBehaviorSelector).length > 0;
    squishBehaviorEnabled  = $(squishBehaviorSelector).length > 0;
    updateSliderAccessibility(); // Update for accessibility.
  }

  $(document)
    .on('Terra.init', initSliderAccessibility)
    .on('click.terra.slidePanel', '[data-slide-panel-target]', slidePanelToggle);
  $(window).on('Terra.resizeEnd', updateSliderAccessibility);
}(jQuery, Terra));

