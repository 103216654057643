(function ($) {
  $(document)
    .on(
      'click',
      'a.terra-u-disabled[aria-disabled="true"], .terra-u-disabled[aria-disabled="true"] a',
      function (e) {
        // Treat links with the terra-u-disabled class and the aria-disabled role like a disabled button
        e.preventDefault();
      }
    )
    .on(
      'contextmenu',
      'a.terra-u-disabled[aria-disabled="true"], .terra-u-disabled[aria-disabled="true"] a',
      function() {
        // Returns false to prevent the context menu from opening
        // http://stackoverflow.com/questions/10864249/disabling-right-click-context-menu-on-a-html-canvas
        return false;
      }
    );
}(jQuery));
