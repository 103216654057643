/* exported Terra.textLengthValidation */

(function ($, Terra, undefined) {
  // NEED TO ADD CUSTOM VALIDATION? SEE https://connect.ucern.com/message/2262769

  // This is the root of the "path" in the locale files (en.json)
  // It is used several times throughout this file
  // Save to a variable to avoid having to write it out repeadedly and have a single consistent point of change
  //   if updates need to be made.
  // Note that Globalize uses the / character to separate each level of nesting in the JSON structure as opposed
  //   to using periods.
  var validationPath = 'Terra/forms/validation/';

  /****************** HELPERS ********************/

  /**
   * Custom error placement for non-standard scenarios to keep the look and flow of our forms clean and consistent.
   * The validation library will automatically populate the inputs on invocation.
   *
   * Adds a tabindex of -1 to the error message to work-around an IE based screen reader bug when aria-describedby
   * points to two elements, help text and error text. More info:
   * http://www.paciellogroup.com/blog/2014/06/aria-labelledby-aria-describedby-support-popular-windows-browsers/
   *
   * @param $error {object} The error DOM element
   * @param $element {object} The element the user filled out (or not) incorrectly
   */
  function formErrorPlacement($error, $element) {
    var parentElement = $element.parent();

    if ($element.data('text-limit')) {
      // Put the message below the text limit counter for the current text input only.
      $error.insertAfter($element.next('.text-counter')).attr('tabindex', '-1');
    } else if ($element.is(':radio, :checkbox')) {
      // If there are multiple checkboxes or radio buttons within a fieldset
      // append the error to the end of the fieldset.
      if (parentElement.parent().is('fieldset')) {
        $error.appendTo($element.parents('fieldset')).attr('tabindex', '-1');
      } else {
        // If it's a checkbox in a label and not in a fieldset
        // append the $error outside the parent label.
        $error.insertAfter(parentElement).attr('tabindex', '-1');
      }
    } else {
      $error.insertAfter($element).attr('tabindex', '-1');
    }
  }

  // Initialize form validation
  // Only call via delegation (it depends on `this` being supplied correctly)
  function initValidation() {
    var $form     = $(this).closest('form'),
        validator = $form.validate();

    $form.find('[data-text-limit]').each(function () {
      Terra.textLengthValidation(this);
    });

    $form.on('reset', function () {
      validator.resetForm();
    });
  }

  /*
   * Passively bridge the gap between Globalize.messageFormatter and $.validator.format.
   * Both use {X} for string interpolation and will step on each others' toes.
   *
   * This method takes infinite arguments with the first two being named.
   * The rest are sent to Globalize.messageFormatter as arguments in the order received.
   * This allows it to return {X} as a string so $.validator.format can properly populate the messages.
   *
   * @param path {String} the JSON path to the translated string for the current locale
   * @param defaultMessage {String} The hardcoded fallback text if Terra cannot Globalize
   *  Terra cannot Globalize if Globalize is not present *and* the default locale is not set.
   */
  function validationFormat(path, defaultMessage) {
    var formatter, args;

    if (Terra.canGlobalize()) {
      formatter = Globalize.messageFormatter(path);
      args      = Array.prototype.slice.call(arguments, 2, arguments.length);

      return formatter(args);
    }

    return defaultMessage;
  }

  /****************** VALIDATION PLUGINS ********************/

  /**
  * From jQuery Validation additional-methods.js
  * https://github.com/jzaefferer/jquery-validation/blob/master/src/additional/pattern.js
  *
  * Return true if the field value matches the given format RegExp
  *
  * @example $.validator.methods.pattern("AR1004",element,/^AR\d{4}$/)
  * @result true
  *
  * @example $.validator.methods.pattern("BR1004",element,/^AR\d{4}$/)
  * @result false
  *
  * @name $.validator.methods.pattern
  * @type Boolean
  * @cat Plugins/Validate/Methods
  */
  $.validator.addMethod('pattern', function (value, element, param) {
    if (this.optional(element)) {
      return true;
    }

    if (typeof param === 'string') {
      // THIS LINE DEVIATES FROM jQuery Validation's opinion.
      // We added '^(?:' and ')$' to follow HTML5 pattern matching conventions
      // '^(?:' match from the start of the string
      // ')$' match at the end of the string
      // http://www.w3.org/html/wg/drafts/html/master/forms.html#the-pattern-attribute
      param = new RegExp('^(?:' + param + ')$');
    }
    return param.test(value);
  }, 'Invalid format.');

  /****************** VALIDATION SETTINGS ********************/

  // Do this once as opposed to on a per <form> basis.
  $.validator.setDefaults({
    errorClass     : 'form-error',
    errorElement   : 'small',
    errorPlacement : formErrorPlacement
  });

  // Override the default messages to align validation with our requirements
  // https://wiki.ucern.com/display/pophealth/BLS001+Forms#BLS001Forms-3.%28FUTURE%29ValidationRequirements
  $.extend($.validator.messages, {
    date      : Terra._i18n(validationPath + 'date', 'Enter a valid date in the "mm/dd/yyyy" format.'),
    digits    : Terra._i18n(validationPath + 'digits', 'Enter only digits.'),
    email     : Terra._i18n(validationPath + 'email', 'Enter a valid email address.'),
    equalTo   : Terra._i18n(validationPath + 'equalTo', 'Enter the same value again.'),
    number    : Terra._i18n(validationPath + 'number', 'Enter a valid number.'),
    remote    : Terra._i18n(validationPath + 'remote', 'Fix this field.'),
    required  : Terra._i18n(validationPath + 'required', 'This field is required.'),
    url       : Terra._i18n(validationPath + 'url', 'Enter a valid URL.'),
    max       : validationFormat(validationPath + 'max', 'Enter a value less than or equal to {0}.', '{0}'),
    maxlength : validationFormat(validationPath + 'maxlength', 'Enter less than {0} characters.', '{0}'),
    min       : validationFormat(validationPath + 'min', 'Enter a value greater than or equal to {0}.', '{0}'),
    minlength : validationFormat(validationPath + 'minlength', 'Enter at least {0} characters.', '{0}'),
    range     : validationFormat(validationPath + 'range', 'Enter a value between {0} and {1}.', '{0}', '{1}')
  });

  /****************** CUSTOM VALIDATION ********************/

  /**
   * Sets up text length validation on a textarea.
   * Adds a counter that keeps track of available characters. Based on data-text-length.
   * Hooks the text area up to jQuery Validation.
   *
   * DO NOT call this method on a form that has not had jQuery validation setup on it.
   *
   * @method textLengthValidation
   * @chainable
   * @param textarea {object} A text area to set text validation up for.
   */
  Terra.textLengthValidation = function (textarea) {
    var getRemaining, updateCounter,
        $counter = $('<small class="text-counter"></small>'),
        $field   = $(textarea),
        max      = $field.data('text-limit');

    if ($field.data('rendered') === undefined) {
      $field.data('rendered', true);
      $field.after($counter);

      getRemaining = function () {
        return max - $field.val().length;
      };

      updateCounter = function () {
        var remaining = getRemaining();

        $counter.text(Globalize.formatMessage('Terra/truncateText/text_remaining', remaining));

        if (remaining < 0) {
          $counter.addClass('text-error');
        } else {
          $counter.removeClass('text-error');
        }
      };

      updateCounter();

      $field.rules('add', { maxlength: max });

      // Updates counter when text is modified.
      $field.on('input propertychange', updateCounter);

      // Needed because default reset will not update the counter.
      $field.closest('form').on('reset', function () {
        $field.val('').trigger('propertychange');
      });
    }

    return this;
  };

  /****************** VALIDATION DELEGATES ********************/

  $(document.body)
    // Filter on novalidate since jQuery validation will add that to cancel HTML5 form validation.
    // We also do not want to add validation to a form where a consumer has explicitly requested none.
    .on('focus.terra.forms.validation', 'form:not([novalidate])', initValidation)
    .on('click.terra.forms.validation', 'form:not([novalidate]) :submit', initValidation);
}(jQuery, Terra));
