/* exported Terra.getMediaQueryName */

(function (Terra) {
  /* Extracts currently active media query from $selector:after,
   * where the CSS is adding it as a pseudo-element.
   * See base.less for more details.
   *
   * Returns the current media query as a t-shirt size:
   *
   * mq = Terra.getMediaQueryName():
   * > 'xl'
   */
  Terra.getMediaQueryName = function () {
    var container = document.querySelectorAll('body')[0];

    // Ensure getComputedStyle is available before use.
    if (container && window.getComputedStyle) {
      return window.getComputedStyle(container, ':after').getPropertyValue('content').replace(/'|"/g, '');
    }
  };
}(Terra));
