// TERRA FAST CLICK INITIALIZATION
// ===============================

(function ($, Terra) {

  /**
   * Initializes the FastClick library.
   */
  Terra._initFastClick = function() {
    if (typeof FastClick !== 'undefined') {
      FastClick.attach(document.body);
    }
  };

  $(function () {
    Terra._initFastClick();
  });

}(jQuery, Terra));
