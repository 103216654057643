(function ($, Terra) {

  Terra.Popups = {};
  /**
   * Helper that checks data attributes set on popup trigger
   * to build out popup options and initializes a tooltipster instance.
   * @param  {Object} $elem     jQuery object of element used to trigger the popup.
   *                            Data attributes are pulled from this element as part of the popup data-api.
   * @param  {Object} popupData An object which stores all of the data attributes and their values which have
   *                            been set on $elem.
   * @param  {Object} options   An object which stores default options for each type of popups: click, hover, onload.
   *                            Values from popupData are added to this object.
   *                            This object is then passed to tooltipster when it is initialized.
   */
  function _initPopup($elem, popupData, options) {
    // Boolean check ensures this does not return false positive when arrow is set false.
    if (typeof popupData.popupArrow === 'boolean') {
      options.arrow = popupData.popupArrow;
    }

    if (popupData.popupContent) {
      // Injects HTML from DOM element specified in data attribute into the generated popup.
      options.functionInit = function() {
        return $(popupData.popupContent).html();
      };
    }

    // Configure a helper to be invoked each time before the popup opens.
    options.functionBefore = function($elem, continueTooltip) {
      // Show the tooltip right away without blocking.
      continueTooltip();

      // Create a helper for the consumer which populates the popup with HTML content.
      function populateContent(HTMLContent) {
        return $elem.tooltipster('content', HTMLContent);
      }

      // Invoke the trigger.
      $elem.trigger('Terra.Popup.open', [populateContent]);
    };

    // Configure a helper to be invoked each time after the popup closes.
    options.functionAfter = function($elem) {
      $elem.trigger('Terra.Popup.close');
    };

    // Always enable the content as HTML.
    options.contentAsHTML = 'true';

    // Sets max-width to user defined size
    // Defaults to 500px
    options.maxWidth = popupData.popupMaxwidth || 500;

    // Set popup position to open to user defined position
    // Defaults to top
    options.position = popupData.popupPosition || 'top';

    if (popupData.popupPosition) {
      var position = popupData.popupPosition.toLowerCase(),
          directionality = $(document).attr('dir');

      if (position === 'start') {
        options.position = ((directionality === 'rtl') ? 'right' : 'left');
      } else if (position === 'end') {
        options.position = ((directionality === 'rtl') ? 'left' : 'right');
      } else {
        options.position = position;
      }
    }

    // Initialize tooltipster instance with options set on $elem
    $elem
      .tooltipster(options)
      .tooltipster('show');
  }

  /*
   * Wrapper to initialize popup on hover funtionality
   */
  Terra.Popups.showHover = function () {
    // Using direct event binding as mouseover/mouseout is fired very frequently on document
    $('[data-popup="hover"]')
      .on('mouseover', showHandler)
      .on('mouseout', {trigger: 'hover'}, hideHandler);
  };

  /*
   * Show popup on click/hover funtionality
   *
   * @param {String|Object} popup - The popup to be shown.
   */
  Terra.Popups.show = function(popup) {
    var $elem     = $(popup),
        popupData = $elem.data(),
        options   = {
          debug       : false, //  Set to false to disable Tooltipster from logging to the console.
          interactive : true, // Allows users the possibility to interact with the popup without closing it.
          trigger     : 'click', // Default to click to open popup
          autoClose   : false // Broken by tooltipster - https://github.com/iamceege/tooltipster/issues/356
        };

    // If hover specified as trigger to open, set hover options
    if (typeof popupData.popup !== 'undefined' && popupData.popup.toLowerCase() === 'hover') {
      options.trigger = 'hover';
    }

    _initPopup($elem, popupData, options);
  };

  /**
   * Hides a popup.
   *
   * @method hide
   * @chainable
   * @param {String|Object} popup - The popup to hide.
   */
  Terra.Popups.hide = function(popup) {
    $(popup).tooltipster('hide');
  };

  /**
   * Hides a popup that is hidden through a trigger.
   *
   * @method hide
   * @chainable
   * @param {String|Object} popup - The popup to hide.
   * @param {Object} triggerOptions (Optional) - object of options used to override the defaults.
   * @param {String} [triggerOptions.trigger] - The trigger type for the popup (Ex: 'auto-close')
   * @param {String} [triggerOptions.triggerTarget] - The trigger to close the popup for
   */
  Terra.Popups.hideWithTrigger = function(popup, triggerOptions) {
    var $elem     = $(popup),
        popupData = $elem.data(),
        popupOptions = triggerOptions || {},
        $trigger;

    switch (popupOptions.trigger) {
      case 'popup-button':
        // If a popup to close has been specified, close it.
        if (typeof popupData.popupClose !== 'undefined') {
          $(popupData.popupClose).tooltipster('hide');
        }
        break;
      case 'tab-button':
        $('[role="tabpanel"] .tooltipstered[data-popup]').tooltipster('hide');
        break;
      case 'content-toggler':
        $elem.find('.tooltipstered[data-popup]').tooltipster('hide');
        break;
      case 'auto-close':
        // Fake auto close for dialogs because tooltipster broke it - https://github.com/iamceege/tooltipster/issues/356
        $trigger = $(popupOptions.triggerTarget).closest('.tooltipstered');

        // Close all other tooltipsters if a trigger is clicked, otherwise close all tooltipsters
        if ($trigger.length) {
          $('.tooltipstered:not([data-popup-auto-close="false"])').not($trigger).tooltipster('hide');
        } else if (!$(popupOptions.triggerTarget).closest('.tooltipster-base').length) {
          $('.tooltipstered:not([data-popup-auto-close="false"])').tooltipster('hide');
        }
        break;
      case 'modal-close':
        $('.mfp-hide .tooltipstered').tooltipster('hide');
        break;
      default:
        $elem.tooltipster('hide');
        break;
    }
  };

  function hoverHandler() {
    Terra.Popups.showHover();
  }

  function showHandler() {
    Terra.Popups.show(this);
  }

  function hideHandler(e) {
    var eventData = e.data || {};

    Terra.Popups.hideWithTrigger(this, {trigger: eventData.trigger, triggerTarget: e.target});
  }

  // Bind showPopupOnload to Terra.init so consumers can re-init as needed and scope to a parent container
  // Set up click delgates for other popup actions
  // Set up event listeners to hide popups in content areas that can be toggled
  $(document)
    .on('Terra.init Terra.hover.popup.show', hoverHandler)
    .on('click.terra.popup.show', '[data-popup="click"]', showHandler)
    .on('click.terra.popup.hide', '[data-popup-close]', {trigger: 'popup-button'}, hideHandler)
    .on('Terra.tabClick', {trigger: 'tab-button'}, hideHandler)
    .on('Terra.toggler.hidden Terra.contentPanelToggled',  {trigger: 'content-toggler'}, hideHandler)
    //html selector prevents issue when clicking on body margins
    .on('click.terra.popup.hide', 'html', {trigger: 'auto-close'}, hideHandler)
    .on('terra.modal.close', {trigger: 'modal-close'}, hideHandler);
}(jQuery, Terra));
