(function ($) {
  /****************** SPECIAL FORMS ********************/

  var autoSubmitSelector =
    '[data-auto-submit] select, [data-auto-submit] [type=checkbox], [data-auto-submit] [type=radio]';

  // Auto submits the form on any change event triggered on select elements, radio and checkbox inputs.
  function autoSubmitHandler() {
    $(this).closest('form').submit();
  }

  $(document)
    .on('change', autoSubmitSelector, autoSubmitHandler);
}(jQuery));
