(function ($, Terra) {
  function toggleActiveGroupedButton() {
    var $this = $(this),
      toggle = $this.parent().data('toggle');

    if (toggle === 'buttons-radio') {
      Terra.ariaSelected($this);
    } else if (toggle === 'buttons-checkbox') {
      Terra.ariaSelected($this, { selectStyle: 'multiple' });
    }
  }

  $(document).on('click.terra[aria-selected]', '.btn-group .btn', toggleActiveGroupedButton);
}(jQuery, Terra));
