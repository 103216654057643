(function ($) {
  // Transition and Remove
  // Transitions the element then removes it from the DOM.
  //
  // @chainable
  // @param fn    {String}        - The name of the jQuery function to invoke on the element.
  // @param speed {String|Number} - The time to transition and remove the element.
  $.fn.transitionAndRemove = function (fn, speed) {
    return this.each(function () {
      var $this = $(this);

      speed = speed || 'normal';

      $this[fn](speed, function () {
        $this.remove();
      });
    });
  };
}(jQuery));
