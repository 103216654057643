/* exported Terra.Navigation */

// Navigation API
// --------------------------------------------------

(function ($, Terra, undefined) {
  Terra.Navigation = {};
  var activeClass            = 'terra-Navigation--active',
      parentActiveClass      = 'terra-Navigation--active-parent',
      grandparentActiveClass = 'terra-Navigation--active-grandparent';

  // PUBLIC FUNCTIONS
  // ------------------------------------------------

  // Setups a navigation panel to be the currently active panel, preparing it's ancestors for backwards navigation
  // $nav - Navigation panel to become active
  Terra.Navigation.activateNav = function($nav) {
    var $parentNav       = $('[aria-owns="' + $nav.attr('id') + '"]').closest('[role="navigation"]'),
        $grandparentNav = $('[aria-owns="' + $parentNav.attr('id') + '"]').closest('[role="navigation"]');

    Terra.activate($parentNav, parentActiveClass);
    Terra.activate($grandparentNav, grandparentActiveClass);

    // When going back, sometimes there is no parent or grandparent to remove active classes
    // They must be manually removed in that case
    $nav.removeClass(parentActiveClass + ' ' + grandparentActiveClass);
    Terra.activate($nav, activeClass);
  };

  // PRIVATE FUNCTIONS
  // ------------------------------------------------

  // Handles what happens when a navigation element is clicked.
  // When the back button is clicked, go up a level in the navigation.
  // When a element is clicked that points to another navigation panel, make that navigation panel active.
  // When an endpoint is clicked (no aria-owns), select the element and close the navigation on smaller screens.
  function handleNavigationClick(event) {
    var $this = $(this);

    if ($this.hasClass('terra-Navigation-item--back')) {
      Terra.Navigation.activateNav($('[aria-owns="' + $this.closest('[role="navigation"]').attr('id') + '"]')
          .closest('[role="navigation"]'));
    } else {
      if ($this.attr('aria-owns') !== undefined) {
        event.preventDefault(); // Don't navigate to href

        Terra.Navigation.activateNav($('#' + $this.attr('aria-owns')));
      } else {
        selectItem($this);
      }
    }
  }

  // Add selected attirbutes and a status indicator to the selected navigation item and
  // close the navigation if on a smaller screen - but do not do this for util items
  function selectItem($item) {
    if (!$item.parent().hasClass('terra-Navigation-utils')) {
      Terra.ariaSelected($item);
      $item.siblings().removeClass('status-accent');
      $item.addClass('status-accent');

      if (isMinimalScreen() && $('.terra-Navigation').attr('aria-hidden') !== 'true') {
        toggleNavigation();
      }
    }
  }

  // Initialize navigation, adding an overlay if a navigation exists.
  function initializeNavigation() {
    var $mainNav = $('.terra-Navigation');

    populateMoreDropdown();
    populateUtilItems();

    if ($mainNav.length !== 0) {
      // Only add overlay once incase of reinit
      if ($('.terra-Navigation-overlay').length === 0) {
        $('<div class="terra-Navigation-overlay" tabindex="-1"></div>').insertAfter($mainNav);
      }

      if(isMinimalScreen()) {
        moveTopNavToSideNav();
      }
    }

    updateTopNavLinks();
  }

  // Populates the more dropdown with top navigation links that are shown/hidden based on available space
  function populateMoreDropdown() {
    var $moreDropdown = $('.terra-Navigation-more'),
        $topNavMenu   = $('.terra-Navigation-top [role="navigation"]'),
        $li           = $('<li/>');

    // Only populate more dropdown menu once
    if ($topNavMenu.length !== 0 && $moreDropdown.find('[role="menu"]').children().length === 0) {
      $topNavMenu.find('.terra-Navigation-item').each(function () {
        // Make a copy of the navigation item link and place a list item of it in the more dropdown
        $moreDropdown.find('[role="menu"]').append($li.clone().append($(this).find('a').clone()));
      });
    }
  }

  // Populates the utilities section of the top nav with navigation items that are shown in the side nav
  function populateUtilItems() {
    var $utilDropdown = $('.terra-Navigation-utils');

    // Only populate navigation items once
    if ($utilDropdown.find('.terra-Navigation-item').length === 0) {
      $utilDropdown.find('[role="menu"] li').each(function () {
        var $li = $(this).clone();

        $utilDropdown.append($li.addClass('terra-Navigation-item'));
      });
    }
  }

  // Returns true if the screen is either XXS, XS, or S
  function isMinimalScreen() {
    var mediaQuery = Terra.getMediaQueryName();

    return mediaQuery === 'xxs' || mediaQuery === 'xs' || mediaQuery === 's';
  }

  // Hides/shows the navigation
  function toggleNavigation() {
    var $mainNav = $('.terra-Navigation');

    if ($mainNav.attr('aria-hidden') === 'true') {
      $mainNav.attr('aria-hidden', 'false').trigger('Terra.navigation.navShown');
    } else {
      $mainNav.attr('aria-hidden', 'true').trigger('Terra.navigation.navHidden');
    }
  }

  // In case of using default navigation toggle, the navigation menu will be
  // re-shown when screens move from small to large.
  // This behavior can be disabled by setting the Terra.Navigation.showOnResize to false
  // If there is a top navigation, it is also added to the side navigation.
  function displayNavigationOnResize() {
    if (isMinimalScreen()) {
      moveTopNavToSideNav();
    } else {
      revertMoveTopNavtoSideNav();

      if ($('.terra-Navigation').attr('aria-hidden') === 'true' && Terra.Navigation.showOnResize !== false) {
        toggleNavigation();
      }
    }
  }

  // Move the top menu to the side navigation
  function moveTopNavToSideNav() {
    var $topNavigationMenu = $('.terra-Navigation-top [role="navigation"]'),
        $navigationLogo    = $('.terra-Navigation-logo');

    // Only move the menu if it is in top nav
    if ($topNavigationMenu.length !== 0) {
      $topNavigationMenu.children('.terra-Navigation-item').show();
      $('.terra-Navigation-more').hide();

      $topNavigationMenu.insertAfter($navigationLogo);

      // Re-activate active menu to add appropriate styles to new top menu`
      Terra.Navigation.activateNav($(document.getElementsByClassName(activeClass)));
    }
  }

  // Move the top menu to the top navigation
  function revertMoveTopNavtoSideNav() {
    var $topNavigationMenu = $('#terra-top-menu'),
        $topNav            = $('.terra-Navigation-top');

    // Only move the menu if there is a top navigation to move it to
    if ($topNav.length !== 0) {
      $topNavigationMenu.prependTo($topNav);

      // If top menu is active make the next menu active in the side nav
      if ($topNavigationMenu.hasClass(activeClass)) {
        Terra.Navigation.activateNav($('.terra-Navigation > [role="navigation"]').first());
      }

      // Remove side nav active styles in top menu
      $topNavigationMenu.removeClass(activeClass).removeClass(parentActiveClass).removeClass(grandparentActiveClass);
    }
  }

  // Hide/show the top navigation links and the more dropdown links based on how much available space there is.
  // The less available space, the more links will be hidden and shown in the dropdown and vice versa.
  function updateTopNavLinks() {
    var availableWidth, totalWidth,
        $topNavMenu    = $('.terra-Navigation-top [role="navigation"]'),
        $topDropdown   = $('.terra-Navigation-more');

    // Only do calculations if the menu is in the top navigation
    if ($topNavMenu.length !== 0) {
      $topDropdown.show(); // Show to get correct width

      availableWidth = $topNavMenu[0].getBoundingClientRect().width;
      totalWidth     = $topNavMenu.find('.terra-Navigation-utils')[0].getBoundingClientRect().width; // Taken up by util

      $topNavMenu.children('.terra-Navigation-item').each(function (index) {
        var $associatedDropdownLink = $($('.terra-Navigation-more [role="menu"]').find('li')[index]),
            isLastElement        = (index === $topNavMenu.children('.terra-Navigation-item').length - 1),
            checkWidth;

        $(this).show(); // Show to get correct width
        $associatedDropdownLink.hide();

        totalWidth += this.getBoundingClientRect().width;

        // Don't check if the more dropdown will fit with the last element, as they'll never be together (so sad)
        checkWidth = isLastElement ? totalWidth : totalWidth + $topDropdown[0].getBoundingClientRect().width;

        if (checkWidth > availableWidth) {
          $(this).hide();
          $associatedDropdownLink.show();
          availableWidth = 0; // starting to hide elements, hide every future element
        }
      });

      if (totalWidth < availableWidth) {
        $topDropdown.hide();
      }
    }
  }

  $(document)
    .on('click', '.terra-Navigation [role="navigation"] .terra-Navigation-item', handleNavigationClick)
    .on('click', '[data-toggle="navigation"], .terra-Navigation-overlay', toggleNavigation)
    .on('Terra.init', initializeNavigation);

  $(window)
    .on('Terra.resizeEnd', displayNavigationOnResize)
    .on('Terra.resizeEnd', updateTopNavLinks);
}(jQuery, Terra));
