/* exported Terra.Alerts */

(function ($, Terra) {
  // Alerts
  // --------------------------------------------------
  Terra.Alerts = {};

  // Initialize existing alert messages.
  // Dismissible & site-level alerts will have close buttons added to them.
  // Auto-hiding alerts will be flagged for removal based on the data-remove attribute.
  //
  // @chainable
  // @param selector {String} - The css selector of a parent object to limit the scope of the init function.
  Terra.Alerts.init = function (selector) {
    selector = selector || '';

    // Mark alerts as rendered.
    // If an alert is marked to auto-remove, flag it for auto-removal with the appropriate delay.
    $(selector + ' .alert:not([data-rendered])')
      .attr('data-rendered', true)
      .filter(function () {
        // An alert should only be auto-removed if a time is specified and is greater than zero.
        // A dismiss time of zero or non-numeric implies that it should never be auto-removed.
        return $(this).attr('data-remove') > 0;
      })
      .each(function () {
        var $this = $(this);

        // Remove after data-remove time tranlated to seconds
        setTimeout(function () {
          $this.transitionAndRemove('slideUp');
        }, $this.data('remove') * 1000);
      });

    return this;
  };

  // Adds an alert to the page at the given selector
  //
  // @method addAlert
  // @chainable
  // @param selector    {String}  - A jQuery string selector.
  // @param msg         {String}  - The message to display in the alert.
  // @param status      {String}  - OPTIONAL - Type of alert - info, success, warning, or error. Defaults to info.
  // @param time        {Number}  - OPTIONAL - Time, in seconds, to wait before removing the element. 0 = don't remove.
  // @param dismissible {Boolean} - OPTIONAL - Whether the alert is dismissible or not (Not dismissible by default)
  // Dismissible alerts will have close buttons added to them.
  Terra.Alerts.addAlert = function (selector, msg, status, time, dismissible) {
    var $dismissButton   = null,
        $alert           = $(document.createElement('div')),
        $alertMessage    = $(document.createElement('p'));

    if (dismissible) {
      $dismissButton = $(document.createElement('button'))
        .addClass('icon-dismiss')
        .attr({
          'aria-label': 'Dismiss',
          'type'      : 'button'
        });
    }

    // Add the message content to the alertMessage object
    $alertMessage.html(msg);

    // Assemble the alert object
    $alert
      .addClass('alert ' + 'alert-' + (status || 'info'))
      .attr({
        'data-remove': time,
        'role'       : 'alert'
      })
      .html($alertMessage)
      .prepend($dismissButton)
      .appendTo(selector);

    Terra.Alerts.init(selector);

    return this;
  };

  // Adds a site-level alert to the page
  // Site level alerts are always dismissible
  //
  // @method addSiteAlert
  // @chainable
  // @param msg    {String} - The message to display in the alert.
  // @param status {String} - OPTIONAL - Which type of alert it is - success, warning, or error. Defaults to error.
  // @param time   {Number} - OPTIONAL - The time, in seconds, to wait before removing the element. 0 = don't remove.
  Terra.Alerts.addSiteAlert = function (msg, status, time) {
    return Terra.Alerts.addAlert('#alert-site-level', msg, status, time, true);
  };

  // Appends an informational alert to the page at the given selector.
  //
  // @method info
  // @chainable
  // @param selector    {String}  - A jQuery string selector.
  // @param msg         {String}  - The message to display in the alert.
  // @param options     {Hash}    - Options hash to specify optional attributes.
  //        time        {Number}  - The time, in seconds, to wait before removing the element. 0 = don't remove.
  //        dismissible {Boolean} - Boolean to specify whether the alert is dismissible or not.
  Terra.Alerts.info = function (selector, msg, options) {
    if (options) {
      return Terra.Alerts.addAlert(selector, msg, 'info', options.time, options.dismissible);
    } else {
      return Terra.Alerts.addAlert(selector, msg, 'info');
    }
  };

  // Appends a success alert to the page at the given selector.
  //
  // @method success
  // @chainable
  // @param selector    {String}  - A jQuery string selector.
  // @param msg         {String}  - The message to display in the alert.
  // @param options     {Hash}    - Options hash to specify optional attributes.
  //        time        {Number}  - The time, in seconds, to wait before removing the element. 0 = don't remove.
  //        dismissible {Boolean} - Boolean to specify whether the alert is dismissible or not.
  Terra.Alerts.success = function (selector, msg, options) {
    if (options) {
      return Terra.Alerts.addAlert(selector, msg, 'success', options.time, options.dismissible);
    } else {
      return Terra.Alerts.addAlert(selector, msg, 'success');
    }
  };

  // Appends a warning alert to the page at the given selector.
  //
  // @method warning
  // @chainable
  // @param selector    {String}  - A jQuery string selector.
  // @param msg         {String}  - The message to display in the alert.
  // @param options     {Hash}    - Options hash to specify optional attributes.
  //        time        {Number}  - The time, in seconds, to wait before removing the element. 0 = don't remove.
  //        dismissible {Boolean} - Boolean to specify whether the alert is dismissible or not.
  Terra.Alerts.warning = function (selector, msg, options) {
    if (options) {
      return Terra.Alerts.addAlert(selector, msg, 'warning', options.time, options.dismissible);
    } else {
      return Terra.Alerts.addAlert(selector, msg, 'warning');
    }
  };

  // Appends an error alert to the page at the given selector.
  //
  // @method error
  // @chainable
  // @param selector    {String}  - A jQuery string selector.
  // @param msg         {String}  - The message to display in the alert.
  // @param options     {Hash}    - Options hash to specify optional attributes.
  //        time        {Number}  - The time, in seconds, to wait before removing the element. 0 = don't remove.
  //        dismissible {Boolean} - Boolean to specify whether the alert is dismissible or not.
  Terra.Alerts.error = function (selector, msg, options) {
    if (options) {
      return Terra.Alerts.addAlert(selector, msg, 'error', options.time, options.dismissible);
    } else {
      return Terra.Alerts.addAlert(selector, msg, 'error');
    }
  };

  // Event handlers
  $(document)
    .on('click.terra.alert-dismiss', '.alert .icon-dismiss', function () {
      $(this).parent('.alert').transitionAndRemove('slideUp');
    })
    .ajaxComplete(function () {
      Terra.Alerts.init();
    });

  $(function () {
    Terra.Alerts.init();
  });
}(jQuery, Terra));
