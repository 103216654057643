(function ($, Terra, window, undefined) {
  var $dropdownButton,
      smallScreenSizes   = ['xxs', 'xs'],
      translateHashRegex = /^(#?)tab-/;

  // Clonable dropdown button to efficiently create as many buttons as needed
  $dropdownButton = $(
    '<button class="btn" aria-expanded="false" aria-haspopup="true" data-toggle="dropdown">' +
      '<span></span>' +
      '<span class="icon-decrement"></span>' +
    '</button>'
  );

  /*
   * Basic tab functionality
   * Initializes all tabs scoped within the event target
   * This function will be invoked by Terra.init
   *
   * e - the JS event.
   */
  function tabs(e) {
    $(e.target).find('[role~="tablist"]').each(function () {
      var selectedElemText,
          $this     = $(this),
          $controls = $this.find('a');

      /*
       * Adjust aria properties for tabs corresponding with the window location hash
       */
      if (window.location.hash) {
        $controls.each(function () {
          // Update the aria-selected attribute based on the hash
          if (this.hash === window.location.hash) {
            // Remove aria-selected for all tabs and then set it for the tab
            // corresponding to the URL hash
            $controls.attr('aria-selected', false);
            $(this).attr('aria-selected', true);

            selectedElemText = this.text;

            // We found a match, there's no reason to continue this each loop
            return false;
          }
        });
      }

      if (selectedElemText === undefined) {
        selectedElemText = $controls.first().text();
      }

      // Tabs represented as button groups are not supposed to roll up.
      if (!$this.hasClass('btn-group')) {
        injectRollupDOM($this, selectedElemText);
      }

      // Hide all sections belonging to non-selected tabs
      _tabHideTargets($controls);

      // Adjust aria properties for sections that are visible
      $controls.filter('[aria-selected="true"]').each(function () {
        var $myTarget = $(_tabTranslateFakeHash(this.hash));
        $myTarget.attr('aria-expanded', true);
        $myTarget.attr('aria-hidden', false);
      });
    });

    contextToggle();
  }

  // Add the classes, roles, and elements necessary for tabs to roll up
  function injectRollupDOM($tabs, selectedElemText) {
    var $button = $dropdownButton.clone();

    $button.children().first().html(selectedElemText);

    // Add the correct aria roles necessary for tabs to roll up
    // Add a button to use when rolled up
    $tabs
      .attr('role', 'tablist menu')
      .parent()
        .addClass('has-tabs') // This is needed as a CSS/JS hook for styling and behavior.
        .prepend($button);
  }

  // Remove or add dropdown styles from tabs based on screen size
  function contextToggle() {
    var mq    = Terra.getMediaQueryName(),
        $tabs = $('.has-tabs');

    if ($.inArray(mq, smallScreenSizes) > -1) {
      $tabs
        .addClass('dropdown')
        .find('[role~=tablist]')
          .attr('aria-hidden', 'true');

      $tabs.each(function () {
        updateActiveText.call(this);
      });
    }
    else {
      $tabs
        .removeClass('dropdown')
        .find('[role~=tablist]')
          .removeAttr('aria-hidden');
    }
  }

  // Helper to hide non active targets
  // Uses hide instead of fadeOut to keep the page from jumping when a user clicks on a tab
  function _tabHideTargets($controls) {
    $controls.not('[aria-selected="true"]').each(function () {
      var $myTarget = $(_tabTranslateFakeHash(this.hash));
      $myTarget.hide();
      $myTarget.attr('aria-expanded', false);
      $myTarget.attr('aria-hidden', true);
    });
  }

  // Helper to remove the tab- prefix from fake hashes
  function _tabTranslateFakeHash(hash) {
    return hash.replace(translateHashRegex, '$1');
  }

  // Finds the related dropdown button and updates its text with the currently selected tab's text
  function updateActiveText() {
    var $this = $(this),
        text  = $this.find('[aria-selected="true"]')[0].text;

    $this.find('button').children().first().html(text);
  }

  // Bind to Terra.init so consumers can re-init as needed and scope to a parent container
  $(document)
    .on('Terra.init', tabs)
    .on('click.terra.tabs', '[role~="tablist"] a', function (e) {
      var $myTarget = $(_tabTranslateFakeHash(this.hash)),
          $this     = $(this),
          $ancestor = $this.closest('[role~="tablist"]'),
          $controls = $ancestor.find('a'),
          hash      = this.hash.replace('#', '');

      $controls.attr('aria-selected', false);
      $(this).attr('aria-selected', true);

      _tabHideTargets($controls);

      // Show the selected target
      // Since content does not fade out, double the fade in speed to keep the animation time
      // perceptually the same to users.
      $myTarget.fadeIn(Terra.transitionSpeed * 2);
      $myTarget.attr('aria-expanded', true);
      $myTarget.attr('aria-hidden', false);

      // If updateHash is set, we don't want the browser to scroll to the anchor.
      // We'll remove the ID from the target, then
      // update the window hash manually and re-insert the ID.
      if ($ancestor.data('update-hash') !== undefined) {
        $myTarget.attr('id', '');
        window.location.hash = hash;
        $myTarget.attr('id', _tabTranslateFakeHash(hash));
      }

      // Trigger an event that consumers can hook onto
      $this.trigger('Terra.tabClick');

      e.preventDefault();
    })
    .on('click.terra.dropdown', '.dropdown.has-tabs', updateActiveText);

  // This is done instead of leveraging media queries for dropdowns with tabs
  // to avoid selector bloat and maintenance issues
  $(window).on('Terra.resizeEnd', contextToggle);
}(jQuery, Terra, this));
