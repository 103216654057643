/* exported Terra.Modal */

(function ($, Terra, window) {
  Terra.Modal = {};
  /*
   * Function to set max-height of .modal-body or height of .modal-full-screen in order to make sure that the modal
   * will stay on screen. Also sets padding-bottom on form modals to make sure contents are not hidden by the footer.
   */
  function _adjustModalHeights($modal) {
    var windowHeight       = $(window).height(),
        modalHeadersHeight = $modal.find('header').outerHeight() + $modal.find('.header-secondary').outerHeight(),
        modalFooterHeight  = $modal.find('footer').outerHeight() || $modal.find('.form-actions').outerHeight();

    if ($modal.hasClass('modal-full-screen')) {
      $modal.find('.modal-body').css('height', windowHeight - modalHeadersHeight - modalFooterHeight);
    } else {
      // 0.85 to match the 85% width that UX requested.
      $modal.find('.modal-body').css('max-height', (windowHeight * 0.85) - modalHeadersHeight - modalFooterHeight);
    }

    if ($modal.hasClass('modal-form')) {
      // Needed to make sure footer doesn't cover any content.
      $modal.css('padding-bottom', modalFooterHeight);
    }
  }

  function openModal() {
    if (this.content) {
      this.content
        .addClass('open')
        .trigger('terra.modal.open');

      if (this.content.attr('data-modal-width')) {
        this.content.css('width', this.content.attr('data-modal-width'));
      }

      _adjustModalHeights(this.content);
    }
  }

  function closeModal() {
    if (this.content) {
      this.content
        .removeClass('open')
        .trigger('terra.modal.close');
    }
  }

  function resizeModal() {
    if (this.content) {
      _adjustModalHeights(this.content);
      $(this.content).trigger('terra.modal.resize');
    }
  }

  var modalOptionDefaults = {
        closeMarkup : '<button class="mfp-close icon-dismiss" aria-label="Dismiss"></button>',
        type        : 'ajax',
        callbacks   : {
          ajaxContentAdded : openModal,
          close            : closeModal,
          resize           : resizeModal
        }
      },
      modalBlockOverrides = {
        closeOnBgClick  : false,
        enableEscapeKey : false
      },
      modalFormOverrides = $.extend(true, {}, modalBlockOverrides, {
        callbacks: {
          close: function() {
            this.content.find('form').trigger('reset');
            closeModal.apply(this);
          }
        }
      });


  /*
   * This will initialize and open a modal with the given options.
   *   modalTrigger - The triggering anchor element for the modal. Pass in null if you are not using a trigger.
   *   options - (optional) Accepted boolean options are:
   *      - block         : Require explicit action to close modal
   *      - form          : The modal contains a form.
   *      - modalLocation : (string) ID pointing to a modal on the page, or href pointing to a remote modal.
   *                                 Required if modalTrigger is null.
   *      - selector      : (standard only) The selector for the modal
   *   magnificOverrides - (optional) magnific overrides
   */
  function _initializeAndOpenModal(modalTrigger, options, magnificOverrides) {
    var magnificOptions = $.extend(true, {}, modalOptionDefaults, magnificOverrides);

    if (options && options.form) {
      $.extend(true, magnificOptions, modalFormOverrides);
    }

    if (options && options.block) {
      $.extend(true, magnificOptions, modalBlockOverrides);
    }

    // Directly open the modal if the modal does not have a trigger associated to it.
    if (modalTrigger) {
      $(modalTrigger)
        .magnificPopup(magnificOptions)
        .magnificPopup('open')
        .attr('data-rendered', true);
    } else if(options.modalLocation) {
      $.extend(true, magnificOptions, {items: {src: options.modalLocation}});
      $.magnificPopup.open(magnificOptions);
    }
  }

  /*
   * This will initialize and open a standard modal which will load a modal via ajax.
   *   modalTrigger - The triggering anchor element for the modal. Pass in null if you are not using a trigger.
   *   options - (optional) Accepted boolean options are:
   *      - block         : Require explicit action to close modal
   *      - form          : The modal contains a form.
   *      - modalLocation : (string) ID pointing to a modal on the page, or href pointing to a remote modal.
   *                                 Required if modalTrigger is null.
   *      - selector      : The selector for the modal
   */
  Terra.Modal.standard = function (modalTrigger, options) {
    if (options.selector) {
      _initializeAndOpenModal(modalTrigger, options, {
        callbacks: {
          parseAjax: function(mfpResponse) {
            var $mfpResponseData  = $(mfpResponse.data),
                $filteredResponse = $mfpResponseData.filter(options.selector);

            // Need to check if the selector was a top-level element because #find will only look at children.
            if ($filteredResponse.length !== 0) {
              mfpResponse.data = $filteredResponse;
            } else {
              mfpResponse.data = $mfpResponseData.find(options.selector);
            }
          }
        }
      });
    } else {
      _initializeAndOpenModal(modalTrigger, options);
    }
  };

  /*
   * This will initialize and open an inline modal which will load a modal on the page.
   *   modalTrigger - The triggering anchor element for the modal. Pass in null if you are not using a trigger.
   *   options - (optional) Accepted boolean options are:
   *      - block         : Require explicit action to close modal
   *      - modalLocation : (string) ID pointing to a modal on the page, or href pointing to a remote modal.
   *                                 Required if modalTrigger is null.
   *      - form          : The modal contains a form.
   */
  Terra.Modal.inline = function (modalTrigger, options) {
    _initializeAndOpenModal(
      modalTrigger,
      options,
      {
        type      : null,
        callbacks : {
          open: openModal, // override default open callback as content has not been loaded yet.
          ajaxContentAdded: null
        }
      }
    );
  };

  /*
   * Triggers the correct modal initialization function based on 'data-toggle' attribute. Defaults to standard modal.
   */
  function _modalOpenHandler(e) {
    var options = {
          form     : this.getAttribute('data-modal-form') || false,
          block    : this.getAttribute('data-modal-block') || false,
          selector : this.getAttribute('data-modal-selector')
        };

    if (this.getAttribute('data-toggle') === 'modal-inline') {
      Terra.Modal.inline($(this), options);
    } else {
      Terra.Modal.standard($(this), options);
    }

    e.preventDefault();
  }

  function _modalCloseHandler() {
    $.magnificPopup.close();
  }

  $(document)
    .on('click.terra.modal', '[data-toggle^="modal"]:not([data-rendered])', _modalOpenHandler)
    .on('click.terra.modal', '.modal [data-dismiss="modal"]', _modalCloseHandler);
}(jQuery, Terra, this));
