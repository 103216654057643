/* exported Terra.justifyHeight */

(function ($, Terra, window) {
  // Justify Height
  // Sets equal heights on direct children elements of the parent element used to call the function.
  // Useful when items are set in horizontal rows and things like borders or backgrounds need uniform height display.
  //
  // Sample usage:
  //   <div data-justify-height="true">
  //     <div>Child with least text</div>
  //     <div>Child with more text that will take up more vertical space</div>
  //   </div>
  Terra.justifyHeight = (function () {
    var $body = $('body');

    var getChildHeight = function () {
      return $(this).height();
    };

    var resetChildHeight = function ($children) {
      $children.height('auto');
    };

    var setChildHeight = function ($children) {
      var childHeights = $children.map(getChildHeight),
          maxChild     = Math.max.apply(Math, $.makeArray(childHeights));

      $children.height(maxChild);
    };

    var justify = function ($selector) {
      $selector
        .find(
          '[data-justify-height=true], .callout-grid.divided[data-columns], .callout-grid.boxed[data-columns]'
        )
        .not('[data-columns=1], [data-columns=""]')
        .each(function () {
          var $children = $(this.children);

          resetChildHeight($children);
          setChildHeight($children);
        });
    };

    // Events scoped to the document object
    $(document).ajaxComplete(function () {
      justify($body); // Must always be called AFTER all component initialization!
    });

    // reset and set child height after window resize
    $(window).on('Terra.resizeEnd', function () {
      justify($body);
    });

    // The function to set Terra.justifyHeight to.
    // Takes in an optional selector to scope what elements it runs against.
    // Can either be a string, DOM node, or jQuery element
    // i.e. Terra.justifyHeight('#my-id'); - only runs on children of #my-id.
    return function (selector) {
      // Cannot use cached version of body because it breaks in jasmine
      justify(selector ? $(selector) : $body);
    };
  }());

  // Run when DOM is ready
  $(function () {
    Terra.justifyHeight('body'); // Must always be called AFTER all component initialization!
  });
}(jQuery, Terra, this));
