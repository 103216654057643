(function ($, undefined) {
  // Clones the given element, updates any IDs to be unique, and appends the clone after the given element.
  // The newly cloned element will be set to be in focus.
  function cloneAdd($toClone) {
    var $newClone  = $toClone.clone(),
        cloneCount = $toClone.data('clone-count') !== undefined ? $toClone.data('clone-count') + 1 : 1;

    // The clonable section may be hidden by default. If so, unhide it and do not clone.
    if ($toClone.is(':hidden')) {
      $toClone.removeAttr('hidden').find('input, textarea, select')[0].focus();
    } else {
      // Do not copy any data
      $newClone.find('input').filter(':checkbox, :radio').prop('checked', false);
      $newClone.find('textarea, select, input').not(':checkbox, :radio').val('');

      // Clear validation errors
      $newClone.find('.form-error').removeClass('form-error').filter('[tabindex="-1"]').remove();

      updateClonedIDs($newClone, cloneCount);

      $newClone.insertAfter($toClone).find('input, textarea, select')[0].focus();

      // Save how many times the target has been cloned
      $toClone.siblings().add($toClone).data('clone-count', cloneCount).find('.clone-remove').removeAttr('disabled');
    }
  }

  // Updates any ID's, names, fors, etc. beloning to the given element or any of the element's children
  // to end with the given count. i.e. 'example-id' becomes 'example-id-c<count>'
  // Regex is used to update the clone specific ID (the '-c###' at the end of the ID)
  function updateClonedIDs($element, count) {
    var elemIDs = {
            'id'               : $element.attr('id'),
            'name'             : $element.attr('name'),
            'for'              : $element.attr('for'),
            'aria-describedby' : $element.attr('aria-describedby')
          },
        regex = /.+(?=-c\d+$)/i;

    // Loop through possible IDs and update if ID is defined.
    $.each(elemIDs, function (id, value) {
      if (value === undefined) { return; }

      if (value.match(regex) !== null) {
        $element.attr(id, value.match(regex) + '-c' + count);
      } else {
        $element.attr(id, value + '-c' + count);
      }
    });

    $element.children().each(function () {
      updateClonedIDs($(this), count);
    });
  }

  function addCloneHandler(e) {
    var $this = $(this);
    e.preventDefault();

    if ($this.data('remote-clone-target')) {
      cloneAdd($('#' + $this.data('remote-clone-target')).children().last());
    } else {
      cloneAdd($this.closest('.clone-target'));
    }

    $this.trigger('Terra.clone.added');
  }

  function removeCloneHandler(e) {
    var $toDelete = $(this).closest('.clone-target');
    e.preventDefault();

    if ($toDelete.siblings().length === 1) {
      $toDelete.siblings().find('.clone-remove').prop('disabled', true);
    }

    $toDelete.remove();
    $(document).trigger('Terra.clone.removed');
  }

  $(document)
    .on('click.terra.clone-add', '.clone-add, [data-remote-clone-target]', addCloneHandler)
    .on('click.terra.clone-remove', '.clone-remove', removeCloneHandler);
}(jQuery));
