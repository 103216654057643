/* exported Terra.escapeChars Terra.unescapeChars */

(function (Terra) {
  /**
   * Terra.escapeChars
   * A global function that escapes the given string for meta-characters.
   * (http://api.jquery.com/category/selectors)
   *
   * Inspired by:
   * http://alexandregiannini.blogspot.com/2011/05/escaping-strings-for-jquery-selectors.html
   *
   * @method escapeChars
   * @param {string} selector - The selector to be sanitized.
   * @return {string} - String with meta-characters escaped.
   *
   */
  Terra.escapeChars = function (selector) {
    return selector.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
  };

  /**
   * Terra.unescapeChars
   * Removes escaped characters for a given string. When given a sanitized string
   * it returns the original unsanitized version back.
   *
   * @method unescapeChars
   * @param {string} selector - The selector to be unsanitized.
   * @return {string} - String with meta-characters unescaped.
   *
   */
  Terra.unescapeChars = function (selector) {
    return selector.replace(/[\\]([!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~])/g, '$1');
  };
}(Terra));
