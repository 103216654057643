/* exported Terra */

// ESTABLISH THE TERRA NAMESPACE OBJECT
// ====================================
var Terra    = window.Terra || {};
window.Terra = Terra;

(function (window, undefined) {
  // TERRA GLOBAL VARIABLES
  // ======================

  // Same as if passing "fast" to jQuery animate.
  Terra.transitionSpeed = 200;

  // Configure locales we support
  Terra.availableLocales = ['en', 'en-US', 'en-GB','fi-FI'];

  // Detect if we can translate or not.
  // Terra cannot use Globalize if Globalize is not present *and* the default locale is not set.
  Terra.canGlobalize = function() {
    return (typeof Globalize !== 'undefined') && (Globalize.locale() !== undefined);
  };

  /**
   * Helper method to passivly translate strings in Terra components
   * @param path {String} the JSON path to the translated string for the current locale
   * @param defaultMessage {String} The hardcoded fallback text if Terra cannot Globalize
   */
  Terra._i18n = function (path, defaultMessage) {
    return (Terra.canGlobalize() ? Globalize.formatMessage(path) : defaultMessage);
  };

  /**
   * Gets the current locale from lang attribute on the html node.
   * @returns The current locale if the lang attribute is set on the html node; otherwise, null.
   */
  Terra.getLocale = function () {
    return $('html').attr('lang');
  };


  /**
   * Sets the locale for the application to use.
   * Note: In order to properly set the locale, you must have the correct cldr data and translations
   *       loaded for the passed in locale.
   *
   * @param - The locale to set the application locale to.
   *
   */
  Terra.setLocale = function(locale) {
    $('html').attr('lang', locale);
    Globalize.locale(locale);

    // For any widgets that are initialized with translations, we want to ensure that they are
    // reinitialized with the correct widgets.
    $('body').trigger('Terra.init');
  };

  // BASE EVENTS AND HANDLERS
  // ========================
  $(window).on('resize', function () {
    // Resize End
    // Set listener on window.resize and trigger custom resizeEnd event when stopped.
    // This keeps people from having to listen to resize event itself, which is triggered a lot.
    // Instead they can just listen for when the resize ended (give or take a few milliseconds)
    if (this.resizeStopListener) {
      clearTimeout(this.resizeStopListener);
    }

    this.resizeStopListener = setTimeout(function () {
      $(this).trigger('Terra.resizeEnd');
    }, 250);
  });

  $(function () {
    // We need to trigger Terra.init once the page is loaded so attached components know to init themselves.
    // This event can be triggered at any time components should attempt to init.
    // Fire this event on the most local DOM node that it is related to.
    // This allows some scripts to ignore the event if it's on an unrelated node.
    $('body').trigger('Terra.init');
  });
}(this));
