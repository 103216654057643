(function ($) {
  // Icons
  // --------------------------------------------------

  // Run when DOM is ready
  $(function () {
    // Calls fontSpy to check if Terra Icons font has loaded or failed to load.
    fontSpy( 'Terra Icons', {
      glyphs: '\uf100\uf101\uf102' // Pass in glyphs from Terra Icons
    });
  });
}(jQuery));
